.footer {
  background-color: #5D3891;
  color: white;
  font-family: 'Arial', sans-serif;
  padding: 40px 20px;
  overflow-x: hidden;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
}

.footer h2 {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: bold;
}

.contact-info {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-item p {
  margin: 0;
  color: #F5F5F5;
  font-size: 18px;
}

.icon {
  font-size: 24px;
  color: #F5F5F5;
}

.info-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap;
  width: 100%;
}

.map-container {
  text-align: center;
  flex: 1;
  min-width: 250px;
}

.footer-bottom {
  background-color: #4A2D6F;
  padding: 10px 20px;
  text-align: center;
}

.footer-bottom p {
  margin: 0;
  color: #F5F5F5;
  font-size: 16px;
}

@media (max-width: 768px) {
  .footer h2 {
    font-size: 24px;
  }

  .contact-item p {
    font-size: 16px;
  }

  .info-row {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .footer h2 {
    font-size: 20px;
  }

  .contact-item p {
    font-size: 14px;
  }

  .map-container {
    font-size: 14px;
    margin: 10px 0;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}

.fade-in {
  opacity: 0;
}

.fade-in.visible .service-card {
  animation: slideInFromRight 0.5s forwards;
}

.fade-in.visible .service-card:nth-child(1) { animation-delay: 0.1s; }
.fade-in.visible .service-card:nth-child(2) { animation-delay: 0.2s; }
.fade-in.visible .service-card:nth-child(3) { animation-delay: 0.3s; }
.fade-in.visible .service-card:nth-child(4) { animation-delay: 0.4s; }

.fade-in-top {
  opacity: 0;
  animation: bounceInDown 1s forwards;
  animation-delay: 0.3s;
  margin-right: 20px;
}

.fade-in.visible, .fade-in-top.visible {
  opacity: 1;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  direction: rtl;
}

.service-card {
  width: 100%;
  max-width: 300px;
  height: 400px;
  background: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 0;
}

.service-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
}

.service-card:hover::before {
  transform: scale(1.1);
}

.service-card:nth-child(1)::before { background-image: url('../assets/1.webp'); }
.service-card:nth-child(2)::before { background-image: url('../assets/2.webp'); }
.service-card:nth-child(3)::before { background-image: url('../assets/3.webp'); }
.service-card:nth-child(4)::before { background-image: url('../assets/4.webp'); }

.service-card h3,
.service-card p,
.service-card button {
  position: relative;
  z-index: 2;
}

.service-card h3 {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 10px;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.8);
}

.service-card p {
  font-size: 1rem;
  color: white;
  line-height: 1.5;
  margin-bottom: 20px;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.8);
}

.service-card button {
  font-size: 1rem;
  color: #FFFFFF;
  background-color: #5D3891;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: flex-start;
}

.service-card button:hover {
  background-color: #4A2E6F;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

h2 {
  direction: rtl;
  margin-right: 10px;
}

.service-card::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70%;
  background-image: linear-gradient(to top, rgba(93, 56, 145, 0.9), rgba(93, 56, 145, 0));
  z-index: 1;
}

@media (max-width: 768px) {
  .service-card {
    width: 90%;
    margin: 10px auto;
  }

  .service-card h3 {
    font-size: 1.25rem;
  }

  .service-card p {
    font-size: 0.9rem;
  }

  .service-card button {
    font-size: 0.9rem;
  }
}
* {
  user-select: none;
  box-sizing: border-box; /* הוספה של box-sizing */
}



body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #F5F5F5;
  overflow-x: hidden; /* למניעת גלילה אופקית */
  position: relative; /* מאפשר למקם את הבועות בצורה נכונה */
  min-height: 100vh; /* גובה מינימלי לגוף העמוד */
}

.bubbles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* כדי לא להפריע ללחיצות */
  overflow: hidden;
  z-index: -1; /* לוודא שהבועות יהיו ברקע מתחת לתוכן */
}

.bubbles span {
  position: absolute;
  bottom: -150px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  animation: bubble 15s infinite ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bubbles span:nth-child(1) {
  left: 10%;
  width: 80px;
  height: 80px;
  animation-duration: 20s;
}

.bubbles span:nth-child(2) {
  left: 20%;
  width: 50px;
  height: 50px;
  animation-duration: 15s;
  animation-delay: 2s;
}

.bubbles span:nth-child(3) {
  left: 30%;
  width: 100px;
  height: 100px;
  animation-duration: 25s;
  animation-delay: 4s;
}

.bubbles span:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-duration: 18s;
  animation-delay: 6s;
}

.bubbles span:nth-child(5) {
  left: 50%;
  width: 70px;
  height: 70px;
  animation-duration: 22s;
  animation-delay: 1s;
}

.bubbles span:nth-child(6) {
  left: 60%;
  width: 40px;
  height: 40px;
  animation-duration: 16s;
  animation-delay: 3s;
}

.bubbles span:nth-child(7) {
  left: 70%;
  width: 80px;
  height: 80px;
  animation-duration: 26s;
  animation-delay: 5s;
}

.bubbles span:nth-child(8) {
  left: 80%;
  width: 50px;
  height: 50px;
  animation-duration: 19s;
  animation-delay: 7s;
}

.bubbles span:nth-child(9) {
  left: 90%;
  width: 60px;
  height: 60px;
  animation-duration: 20s;
  animation-delay: 4s;
}

@keyframes bubble {
  0% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-1000px) scale(1.2);
  }
  100% {
    transform: translateY(-2000px) scale(0.8);
  }
}

/* שאר העיצוב שלך */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 100%; /* הוספה של max-width */
}

h2, h3 {
  color: #5D3891;
}

button {
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  color: white;
  background-color: #5D3891;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #F99417;
}

.service-card, .side {
  background: #F5F5F5;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .service-card, .side {
    width: 100%;
    padding: 15px;
    margin: 5px;
  }

  button {
    width: 100%;
    margin-top: 10px;
  }
}

.Toastify__toast-container {
  z-index: 9999;
}

.Toastify__toast {
  background-color: #fff;
  color: #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.Toastify__toast-icon {
  font-size: 20px;
}

.Toastify__close-button {
  color: #000;
}

.Toastify__toast-body {
  font-size: 16px;
}

.Toastify__progress-bar {
  background: #5D3891;
}

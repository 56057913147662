/* src/styles/FAQ.css */
.faq {
  direction: rtl;
  padding: 20px;
  background: #F5F5F5;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.faq-item {
  margin-bottom: 15px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  margin-right: 20px;
}

.faq-item.visible {
  opacity: 1;
  transform: translateY(0);
}

.faq-item h3 {
  margin-bottom: 5px;
  color: #5D3891;
}

.faq-item p {
  margin: 0;
  color: #333;
}

.faqTitel{
  margin-right: 20px;
}
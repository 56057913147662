/* ServiceModal.css */

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.Overlay.rtl {
  direction: rtl;
}

.Overlay.ltr {
  direction: ltr;
}

.Overlay.ReactModal__Overlay--after-open {
  opacity: 1;
}

.Overlay.ReactModal__Overlay--before-close {
  opacity: 0;
}

.Modal {
  position: relative;
  background: #F5F5F5;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  transform: translateY(-50px);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.Modal.rtl {
  direction: rtl;
}

.Modal.ltr {
  direction: ltr;
}

.Modal.ReactModal__Content--after-open {
  transform: translateY(0);
  opacity: 1;
}

.Modal.ReactModal__Content--before-close {
  transform: translateY(-50px);
  opacity: 0;
}

.close-button {
  position: absolute;
  top: -20px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: orange;
  padding: 5px;
  transition: color 0.3s ease-in-out;
  z-index: 1002;
}

.rtl .close-button {
  right: -20px;
}

.ltr .close-button {
  left: -20px;
}

.close-button:hover {
  color: darkorange;
}

.detailIcon {
  color: #5D3891;
}

.rtl .detailIcon {
  margin-left: 10px;
}

.ltr .detailIcon {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .Modal {
    padding: 15px;
    max-width: 90%;
    max-height: 70vh;
    padding-top: 40px;
  }

  .close-button {
    top: 10px;
  }

  .rtl .close-button {
    right: 10px;
  }

  .ltr .close-button {
    left: 10px;
  }
}
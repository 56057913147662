.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  background: #F5F5F5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #f0f0f0;
}

.side1, .side2 {
  margin: 10px;
  width: 100%;
}

.priceEstimate {
  margin-top: 20px;
  color: #5D3891;
}

.services {
  display: flex;
  flex-direction: column;
}

.serviceLabel {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.serviceIcon {
  margin-right: 10px;
  color: #5D3891;
}

.submitButton {
  background-color: #F99417;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background-color: #5D3891;
}

.dropdown {
  width: 100%;
  display: inline-block;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 2px rgb(204, 204, 204);
  transition: all .5s ease;
  position: relative;
  font-size: 14px;
  color: #474747;
  height: 100%;
  text-align: left;
}

.dropdown .select {
  cursor: pointer;
  display: block;
  padding: 10px;
}

.dropdown .select > i {
  font-size: 13px;
  color: #888;
  cursor: pointer;
  transition: all .3s ease-in-out;
  float: right;
  line-height: 20px;
}

.dropdown:hover {
  box-shadow: 0 0 4px rgb(204, 204, 204);
}

.dropdown:active {
  background-color: #f8f8f8;
}

.dropdown.active:hover,
.dropdown.active {
  box-shadow: 0 0 4px rgb(204, 204, 204);
  border-radius: 2px 2px 0 0;
  background-color: #f8f8f8;
}

.dropdown.active .select > i {
  transform: rotate(-90deg);
}

.dropdown .dropdown-menu {
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0;
  margin-top: 1px;
  box-shadow: 0 1px 2px rgb(204, 204, 204);
  border-radius: 0 1px 2px 2px;
  overflow: hidden;
  display: none;
  max-height: 144px;
  overflow-y: auto;
  z-index: 9;
}

.dropdown .dropdown-menu li {
  padding: 10px;
  transition: all .2s ease-in-out;
  cursor: pointer;
}

.dropdown .dropdown-menu {
  padding: 0;
  list-style: none;
}

.dropdown .dropdown-menu li:hover {
  background-color: #f2f2f2;
}

.dropdown .dropdown-menu li:active {
  background-color: #e2e2e2;
}



@media (min-width: 769px) {
  .contentWrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .side1, .side2 {
    max-width: 48%;
  }

  
}

@media (max-width: 768px) {
  .side1, .side2 {
    width: 100%;
  }
}

.bathCount {
  padding: 30px;
  font-size: 20px;
  font-weight: bolder;
  color: #5D3891;
}
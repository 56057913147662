.accessibility-container {
  position: fixed;
  top: 70%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 1000;
  background: #F99417;
  border-radius: 10px;
}

.accessibility-button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: #5D3891;
  transition: transform 0.3s;
}

.accessibility-button:hover {
  transform: scale(1.1);
}

.accessibility-options {
  position: absolute;
  top: 10%;
  left: 50px;
  transform: translateY(-50%);
  background: #F5F5F5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s forwards;
  z-index: 999;
  max-width: 350px; /* מקסימום רוחב כדי להציג הכל בצורה נוחה */
  overflow: hidden; /* מניעת גלילה פנימית */
}

.accessibility-options h3 {
  margin-top: 0;
  color: #5D3891;
  text-align: center;
}

.button-group {
  display: flex;
  flex-direction: column;
}

.accessibility-options button {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  background: #F99417;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.accessibility-options button:hover {
  background: #E8E2E2;
  color: #5D3891;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.install-message {
  margin-top: 20px;
  padding: 10px;
  background: #ffdddd;
  border: 1px solid #ff5c5c;
  border-radius: 5px;
}

.install-message p {
  margin: 0;
  font-size: 14px;
}

.install-message button {
  margin-top: 10px;
  padding: 5px 10px;
  background: #F99417;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.install-message button:hover {
  background: #E8E2E2;
  color: #5D3891;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate(-10px, -50%);
  }
  to {
    opacity: 1;
    transform: translate(0, -50%);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translate(0, -50%);
  }
  to {
    opacity: 0;
    transform: translate(-10px, -50%);
  }
}

/* src/styles/LanguageSwitcher.css */
.language-switcher {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  z-index: 1000;
}

.language-switcher img {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s;
}

.language-switcher img:hover {
  transform: scale(1.2);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  direction: rtl;
}

.modal {
  background: white;
  padding: 30px;
  border-radius: 15px;
  width: 90%;
  max-width: 400px;
  position: relative;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.modal-close {
  background: none;
  border: none;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #000;
}

.modal h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  text-align: center;
}

.phone-input {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  direction: ltr;
  text-align: left;
}

.otp-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  direction: ltr;
}

.otp-input {
  width: 40px;
  height: 40px;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.modal-button {
  width: 100%;
  padding: 15px;
  background: #5D3891;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-button:hover {
  background: #7F65C1;
}

.modal-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #5D3891;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 480px) {
  .modal {
    padding: 20px;
  }

  .modal h2 {
    font-size: 20px;
  }

  .phone-input {
    font-size: 16px;
  }

  .otp-input {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }

  .modal-button {
    font-size: 16px;
  }
}
/* src/styles/Header.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #5D3891;
  color: white;
  position: relative;
}

.logo {
  height: 50px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #F99417;
}

.mobile-menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav-links {
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #5D3891;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: none;
    z-index: 1000;
  }

  .nav-links.open {
    display: flex;
  }

  .mobile-menu-icon {
    display: block;
  }
}
